export default {
    app_0001: "今すぐ参加",
    app_0002: "我々の使命",
    app_0003: "Gemma の使命は、あなたの個人的なスタイルを引き立てる高級バッグやアクセサリーを見つけるお手伝いをすることです。誰もが欲しがる、入手困難なアクセサリーを当社が見つけますので、そうする必要はありません。売り切れのバッグを追いかけ、無限の順番待ちリストを作り、お気に入りの高級ブランドで「ゲームをする」時代は終わりました。珍しいエルメスのバッグから靴、高級ジュエリーに至るまで、新品未使用のアクセサリーの最も包括的な在庫を厳選しており、在庫があり、翌日にはお手元に届きます。当店は、お気に入りのエルメス バーキン、ケリー、コンスタンス、ピコタンのハンドバッグをはじめ、エルメスのアクセサリー、スカーフ、ジュエリーの幅広い品揃えを専門としています。あなたが次のデザイナー ハンドバッグ、ギフト、または投資アイテムを探しているとき、私たちは信頼できるアドバイザーとなることを目指し、あなたにふさわしいスタイルと洗練さにマッチする本物の高級デザイナー バッグやアクセサリーを見つけることに専念します。自信を持って前に進んでください。大胆になります。表現力豊かに。素直になれ。",
    app_0004: "私たちについて",
    app_0005: "Gemma は 2010 年にニューヨークで設立され、二次市場で本物のエルメスとシャネルの商品を扱う米国で最も信頼できるディーラーです。本物のみのアイテムと可能な限り最高のショッピング体験への当社の取り組みにより、著名人、社交界の著名人、スタイリスト、コンシェルジュ サービス、大規模な電子商取引サイトを含む数千人の顧客ベースを構築することができ、これらすべての人々が当社の取り組みを証明しています。私たちが販売する各商品が本物であること。当社では、新品、新品同様のエルメス ハンドバッグの世界最大のオンライン コレクションを取り揃えており、毎日新しい商品が提供されています。収集価値のあるヴィンテージエルメスの限定コレクションも取り揃えています。さらに、新品のシャネル ハンドバッグ、シャネル ヴィンテージ ハンドバッグ、ゴヤール、エルメス、シャネルのジュエリーやアクセサリーのコレクションも取り揃えています。当社はウェブサイトに掲載されている商品を 100% 在庫しており、即出荷が可能です。お探しの特定の商品がない場合は、コンシェルジュ チームが Find It For Me プログラムを通じてご希望の商品の調達を喜んでお手伝いいたします。通常、営業日の支払い受領後 24 時間以内に発送し、電話、WhatsApp、テキスト、メールによるお問い合わせにはほぼ 24 時間年中無休で対応いたします。",
    app_0006: "1. 電子メールでご連絡いただけます:",
    app_0007: "2. 営業電話でお問い合わせいただけます。",
    app_0008: "3. WhatsApp でメッセージを送信することもできます。",
    app_0009: "# アメリカ",
    app_0010: "  -- 当社のオフィスは月曜から日曜の午前 7 時から午後 22 時（太平洋標準時間）まで営業しています。",
    app_0011: "#オーストラリア",
    app_0012: "  -- 当社のオフィスは月曜から日曜の午前 7 時から午後 5 時 (AEST) まで営業しています。",
    app_0013: "# その他の時間帯は、音声メッセージを残すか電子メールを送信してください。できるだけ早く対応させていただきます。"
};

<template>
  <header>
    <div class="header-inner">
      <div class="logo">
        <img src="./assets/logo.png" alt="" />
      </div>
      <div class="action">
        <div class="item language">
          <el-dropdown @command="changeLang">
            <span class="el-dropdown-link">
              <span :class="['icon-lang', 'icon-' + currLang]"></span>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="(item, index) of langList" :key="index" :command="item.code">{{ item.name }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <!-- <div class="item user">
          <img src="./assets/icon-user.png" alt="" width="26px" height="26px">
        </div> -->
      </div>
    </div>
  </header>
  <router-view />

  <div class="cs" @click="openCS()">
    <div class="cs-inner">
      <img src="./assets/kefu.png" alt="" width="70px" height="70px" />
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { LANGLIST, setup } from "@/locales/index";
import { getCookie } from "./config/utils";
@Options({
  components: {},
})
export default class App extends Vue {
  langList = LANGLIST;
  currLang = getCookie("lang");
  openCS() {
    window.open(window._config.CsLink);
  }
  changeLang(command: string) {
    setup(command);
    window.location.reload();
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

$max-width: 1168px;

@mixin flex($direction: row, $items: center, $justify: flex-start) {
  display: flex;
  flex-direction: $direction;
  align-items: $items;
  justify-content: $justify;
}

html,
body {
  padding: 0;
  margin: 0;
  background: #fafafa;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  height: 72px;
  @include flex(row, center, center);

  .header-inner {
    width: $max-width;
    @include flex(row, center, space-between);
  }

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background-color: rgba(#000, 0.18);
    bottom: 0;
    left: 0;
    right: 0;
  }

  .action {
    display: flex;
    @include flex(row, center);

    .item + .item {
      margin-left: 8px;
    }
  }
}

.cs {
  max-width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  will-change: width, height, transform, opacity;
  backface-visibility: hidden;
  padding: 8px 8px 16px 16px;
  right: 0;
  justify-content: flex-end;
  transition: transform 0.25s ease;
  cursor: pointer;

  .cs-inner {
    display: flex;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    justify-content: center;
    margin-left: auto;
    position: relative;
    background: #e7faf2 none repeat scroll 0 0;
    color: #fff;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
  outline: none !important;
}

.logo {
  img {
    height: 28px;
    padding-left: 10px;
  }
}

.icon-lang {
  background-image: url("./assets/lang-map.png");
  background-repeat: no-repeat;
  width: 35px;
  height: 24px;

  display: inline-block;
  background-position-y: 0px;
  background-size: 34px auto;

  &.icon-zhCN {
    background-position-y: -34px;
  }

  &.icon-viVN {
    background-position-y: -69px;
  }

  &.icon-thTH {
    background-position-y: -104px;
  }

  &.icon-idID {
    background-position-y: -138px;
  }

  &.icon-msMY {
    background-position-y: -173px;
  }

  &.icon-myMY {
    background-position-y: -312px;
  }

  &.icon-zhTW {
    background-position-y: -243px;
  }

  &.icon-deDE {
    background-position-y: -418px;
  }

  &.icon-ptPT {
    background-position-y: -383px;
  }

  &.icon-esES {
    background-position-y: -453px;
  }

  &.icon-koKR {
    background-position-y: -488px;
  }

  &.icon-jaJP {
    background-position-y: -523px;
  }
}

@media (max-width: 1200px) {
  .home {
    padding: 0 24px;
  }

  .header-inner {
    padding-right: 24px;
    max-width: unset;
  }
}

@media (max-width: 840px) {
  .home .grid {
    grid-template-columns: unset !important;

    .item {
      padding: 24px !important;
    }
  }

  .home-inner .btn {
    margin: 24px 0 !important;
  }
}
</style>

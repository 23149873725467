<template>
  <div class="home">
    <div class="home-inner">
      <button class="btn" @click="joinNow">{{ $t("app_0001") }}</button>

      <div class="grid">
        <div class="item">
          <img src="../assets/22.jpg" alt="" />
        </div>
        <div class="item">
          <img src="../assets/33.jpg" alt="" />
        </div>
      </div>

      <div class="grid">
        <div class="item">
          <div class="title">{{ $t("app_0002") }}</div>
          <div class="desc">{{ $t("app_0003") }}</div>
          <img src="../assets/121.jpg" alt="" style="margin-top: 50px" />
        </div>
        <div class="item">
          <div class="title">{{ $t("app_0004") }}</div>
          <div class="desc">
            {{ $t("app_0005") }}
          </div>
          <div class="row">
            <div class="d1">{{ $t("app_0006") }}</div>
            <div class="d2">{{ email }}</div>
          </div>
          <div class="row">
            <div class="d1">{{ $t("app_0007") }}</div>
            <div class="d2">{{ salePhoneLink }}</div>
          </div>
          <div class="row">
            <div class="d1">{{ $t("app_0008") }}</div>
            <div class="d2">{{ whatsAppLink }}</div>
          </div>
          <div class="row">
            <div class="d1">{{ $t("app_0009") }}</div>
          </div>
          <div class="row">
            {{ $t("app_0010") }}
          </div>
          <div class="row">
            <div class="d1">{{ $t("app_0011") }}</div>
          </div>
          <div class="row">
            {{ $t("app_0012") }}
          </div>
          <div class="row">
            <div class="d1">{{ $t("app_0013") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class HomeView extends Vue {
  email = window._config.EmailLink;
  salePhoneLink = window._config.SalePhoneLink;
  whatsAppLink = window._config.WhatsAppLink;
  joinNow() {
    window.open(window._config.BtnLink);
  }
}
</script>

<style lang="scss">
$max-width: 1168px;

@mixin flex($direction: row, $items: center, $justify: flex-start) {
  display: flex;
  flex-direction: $direction;
  align-items: $items;
  justify-content: $justify;
}

.home {
  background: url("../assets/bg.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 100%;
  padding-top: 72px;

  .home-inner {
    max-width: $max-width;
    margin: 0 auto;
  }

  .btn {
    background: #414141;
    margin: 48px 0;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    border: 0;
    outline: 0;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      opacity: 0.9;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 48px;

    img {
      width: 100%;
    }

    .item {
      border-radius: 16px;
      border: 1px solid #e0e0e0;
      padding: 32px;
      background: #fff;

      .title {
        display: inline-flex;
        background: linear-gradient(270deg, rgba(200, 255, 72, 0.3), rgba(89, 255, 214, 0.3));
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        width: auto;
        color: #181c1f;
        margin: 0 0 8px;
        padding: 2px 4px;
        border-radius: 2px;
      }

      .desc,
      .row {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #181c1f;
        margin: 0;
        word-break: break-all;

        .d1 {
          font-weight: bold;
        }

        .d2 {
          color: #2641cf;
          padding-left: 18px;
        }
      }
    }
  }
}
</style>

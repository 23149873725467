export default {
    app_0001: 'Join now',
    app_0002: 'OUR MISSION',
    app_0003: 'Our mission at Gemma is to help you find luxury bags and accessories that compliment your personal style. We find the most coveted and hard-to-get accessories, so you don’t have to. Gone are the days of chasing sold out bags, endless waiting lists and “playing the game” at your favorite luxury brands. From rare Hermès bags, to shoes and fine jewelry, we curate the most comprehensive stock of new and never worn accessories, in stock and in your hands the very next day. We specialize in your favorite Hermès Birkin, Kelly, Constance and Picotin handbags as well as a wide assortment of Hermès accessories, scarves and jewelry. As you search for your next designer handbag, gift or investment piece, we aim to be your trusted advisor, dedicated to finding authentic luxury designer bags and accessories matching the style and sophistication you so well deserve. Move forward with confidence. Be bold. Be expressive. Be yourself.',
    app_0004: 'About Us',
    app_0005: 'Gemma was founded in New York in 2010, Gemma is the most trusted dealer in the United States for authentic Hermès and Chanel merchandise in the secondary market. Our commitment to authentic-only items and to the best shopping experience possible has enabled us to build a client base in the thousands, which include celebrities, socialites, stylists, concierge services, and large e-commerce sites, who all attest to our commitment that each item we sell is authentic. We have one of the largest online collections of store fresh, new and like-new Hermès handbags in the world, with new merchandise being offered every day. We also carry a limited collection of collectible vintage Hermès pieces. In addition, we carry a collection of new Chanel handbags, Chanel vintage handbags, Goyard, and Hermès and Chanel jewelry and accessories. We stock 100% of the items listed on our website, available for immediate shipment. If we do not have a particular piece you are seeking, our concierge team is happy to assist in sourcing your desired item via our Find It For Me program. We typically ship within 24-hours of receipt of payment during business days and we are available to respond to inquiries via phone, WhatsApp, text or email almost 24/7.',
    app_0006: '1. You can reach us via email:',
    app_0007: '2. You can contact us via sales phone:',
    app_0008: '3. You can also message us on WhatsApp:',
    app_0009: '# USA',
    app_0010: '  -- Our offices are open Monday - Sunday from 7:00am - 22:00pm PST',
    app_0011: '# Australia',
    app_0012: '  -- Our offices are open Monday - Sunday from 7:00 am- 5:00pm AEST',
    app_0013: '# During other hours, please leave a voice message or send an email and we will respond as quickly as possible.',
};

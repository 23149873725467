export default {
    app_0001: "지금 가입하세요",
    app_0002: "우리의 미션",
    app_0003: "Gemma의 사명은 귀하의 개인 스타일에 맞는 고급 가방과 액세서리를 찾는 데 도움을 주는 것입니다. 우리는 가장 탐나고 구하기 어려운 액세서리를 찾아주므로 귀하는 그럴 필요가 없습니다. 매진된 가방, 끝없는 대기자 명단, 좋아하는 명품 브랜드에서 \"게임\"을 하던 시대는 지나갔습니다. 희귀한 Hermès 가방부터 신발, 고급 주얼리까지, 새로운 액세서리와 한 번도 착용하지 않은 액세서리로 구성된 가장 포괄적인 재고를 다음날 바로 고객님의 손에 전달해 드립니다. 우리는 귀하가 가장 좋아하는 Hermès Birkin, Kelly, Constance 및 Picotin 핸드백뿐만 아니라 다양한 종류의 Hermès 액세서리, 스카프 및 주얼리를 전문으로 취급합니다. 귀하가 다음 디자이너 핸드백, 선물 또는 투자 제품을 검색할 때 저희는 귀하가 마땅히 받아야 할 스타일과 세련미에 맞는 정통 럭셔리 디자이너 가방과 액세서리를 찾는데 전념하는 귀하의 신뢰할 수 있는 조언자가 되는 것을 목표로 합니다. 자신감을 갖고 앞으로 나아가세요. 대담해지세요. 표현력을 발휘하세요. 너 자신이 되어라.",
    app_0004: "회사 소개",
    app_0005: "Gemma는 2010년 뉴욕에서 설립되었으며, Gemma는 중고 시장에서 정품 Hermès 및 Chanel 상품을 판매하는 미국 내 가장 신뢰받는 딜러입니다. 진품만을 취급하고 최고의 쇼핑 경험을 제공하겠다는 약속을 통해 유명 인사, 사교계 명사, 스타일리스트, 컨시어지 서비스, 대규모 전자 상거래 사이트 등 수천 명의 고객 기반을 구축할 수 있었습니다. 이들은 모두 우리의 약속을 입증합니다. 우리가 판매하는 각 품목은 정품임을 확인합니다. 우리는 매일 새로운 상품이 제공되는 신선하고 새 제품, 새 것과 같은 Hermès 핸드백으로 구성된 세계 최대 규모의 온라인 컬렉션 중 하나를 보유하고 있습니다. 우리는 또한 수집 가능한 빈티지 에르메스 제품의 한정된 컬렉션을 보유하고 있습니다. 이 밖에도 새로운 샤넬 핸드백, 샤넬 빈티지 핸드백, 고야드, 에르메스와 샤넬 주얼리 및 액세서리 컬렉션을 보유하고 있습니다. 우리는 웹사이트에 나열된 품목을 100% 보유하고 있으며 즉시 배송이 가능합니다. 귀하가 찾고 있는 특정 제품이 없는 경우, 컨시어지 팀이 Find It For Me 프로그램을 통해 귀하가 원하는 제품을 찾는 데 기꺼이 도움을 드립니다. 당사는 일반적으로 영업일 중 결제를 받은 후 24시간 이내에 배송하며 거의 연중무휴 24시간 전화, WhatsApp, 문자 또는 이메일을 통해 문의사항에 응답할 수 있습니다.",
    app_0006: "1. 이메일을 통해 당사에 연락하실 수 있습니다:",
    app_0007: "2. 판매 전화를 통해 문의하실 수 있습니다.",
    app_0008: "3. WhatsApp으로 메시지를 보내실 수도 있습니다.",
    app_0009: "# 미국",
    app_0010: "  -- 저희 사무실은 월요일부터 일요일까지 오전 7시부터 오후 22시(PST)까지 영업합니다.",
    app_0011: "# 호주",
    app_0012: "  -- 저희 사무실은 월요일부터 일요일까지 오전 7시부터 오후 5시(AEST)까지 영업합니다.",
    app_0013: "# 그 외 시간에는 음성메시지나 이메일을 남겨주시면 최대한 빠르게 답변해드리겠습니다."
};

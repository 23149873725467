import { createI18n } from 'vue-i18n'
import { getCookie, setCookie } from '../config/utils'
import enUS from "./enUS"
//import zhCN from "./zhCN"
//import thTH from "./thTH"
import viVN from "./viVN"
// import idID from "./idID"
// import msMY from "./msMY"
// import myMY from "./myMY"
// import ptPT from "./ptPT"
import zhTW from "./zhTW"
// import deDE from "./deDE"
// import esES from "./esES"
import jaJP from "./jaJP"
import koKR from "./koKR"

export const LANGLIST = {
  enUS: { key: 'en', code: 'enUS', name: 'English', currency: 'USD', local: enUS },  
  // zhCN: { key: 'zh', code: 'zhCN', name: '简体中文', currency: 'CNY', local: zhCN }, 
  //thTH: { key: 'th', code: 'thTH', name: 'ไทย', currency: 'THB', local: thTH },
  viVN: { key: 'vi', code: 'viVN', name: 'Tiếng Việt', currency: 'VNDK', local: viVN },
  // idID: { key: 'id', code: 'idID', name: 'Indonesia', currency: 'IDRK', local: idID },
  // msMY: { key: 'ms', code: 'msMY', name: 'B.Melayu', currency: 'MYR', local: msMY },
  // myMY: { key: 'my', code: 'myMY', name: 'မြန်မာ', currency: 'KMMK', local: myMY },
  // ptPT: { key: 'pt', code: 'ptPT', name: 'Português', currency: 'EUR', local: ptPT },
  zhTW: { key: 'zh', code: 'zhTW', name: '繁體中文', currency: 'CNY', local: zhTW },  
  // deDE: { key: 'de', code: 'deDE', name: 'Deutsch', currency: 'EUR', local: deDE },
  // esES: { key: 'es', code: 'esES', name: 'Español', currency: 'EUR', local: esES },
  jaJP: { key: 'ja', code: 'jaJP', name: '日本語', currency: 'JPY', local: jaJP },
  koKR: { key: 'ko', code: 'koKR', name: '한국어', currency: 'KRW', local: koKR },
}
const LOCALE_KEY = 'lang'
const DEFALUT_ITEM = Object.values(LANGLIST).find(item => navigator.language.includes(item.key))
const DEFAULT_LANG = getCookie(LOCALE_KEY) || window._config.DefaultLangCode || DEFALUT_ITEM?.code || 'enUS'
interface Locals {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key : string] : any
}
const locales = Object.values(LANGLIST).reduce((curr, prev) => {
  curr[prev.code] = prev.local
  return curr
}, {} as Locals)



const i18n = createI18n({
    legacy: false,
    locale: DEFAULT_LANG,
    messages: locales
});

export const setup = (lang?: string) => {
  if (lang === undefined) {
    lang = getCookie(LOCALE_KEY) as string
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG
    }
  }
  if(lang){
    setCookie(LOCALE_KEY, lang, 7)
  }  
}
setup()
export default i18n

// 读取 Cookie
export function getCookie(name: string): string | null {
    const cookies = document.cookie.split(';');
    
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      const trimmedName = cookieName.trim();
  
      if (trimmedName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
  
    return null;
  }
  
  // 写入 Cookie
 export function setCookie(name: string, value: string, days: number): void {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    const cookieValue = encodeURIComponent(value) + ';' + expires + ';path=/';
    document.cookie = `${name}=${cookieValue}`;
  }
  
export default {
    app_0001: "Tham gia ngay",
    app_0002: "NHIỆM VỤ CỦA CHÚNG TA",
    app_0003: "Sứ mệnh của chúng tôi tại Gemma là giúp bạn tìm được những chiếc túi và phụ kiện sang trọng phù hợp với phong cách cá nhân của bạn. Chúng tôi tìm thấy những phụ kiện được thèm muốn nhất và khó kiếm nhất, vì vậy bạn không cần phải tìm. Đã qua rồi cái thời chạy theo những chiếc túi hết hàng, danh sách chờ đợi dài vô tận và “chơi trò chơi” với những thương hiệu xa xỉ yêu thích của bạn. Từ những chiếc túi Hermès quý hiếm, đến những đôi giày và đồ trang sức cao cấp, chúng tôi tuyển chọn kho phụ kiện mới và chưa từng sử dụng toàn diện nhất, có sẵn trong kho và đến tay bạn ngay ngày hôm sau. Chúng tôi chuyên về túi xách Hermès Birkin, Kelly, Constance và Picotin mà bạn yêu thích cũng như nhiều loại phụ kiện, khăn quàng cổ và trang sức Hermès. Khi bạn tìm kiếm túi xách, quà tặng hoặc món đầu tư thiết kế tiếp theo của mình, chúng tôi mong muốn trở thành cố vấn đáng tin cậy của bạn, tận tâm tìm kiếm những chiếc túi và phụ kiện thiết kế sang trọng đích thực phù hợp với phong cách và sự tinh tế mà bạn rất xứng đáng có được. Hãy tự tin tiến về phía trước. Được in đậm. Hãy biểu cảm. Là chính mình.",
    app_0004: "Về chúng tôi",
    app_0005: "Gemma được thành lập tại New York vào năm 2010, Gemma là đại lý đáng tin cậy nhất ở Hoa Kỳ về hàng hóa Hermès và Chanel chính hãng trên thị trường thứ cấp. Cam kết của chúng tôi đối với các mặt hàng chỉ có hàng chính hãng và trải nghiệm mua sắm tốt nhất có thể đã cho phép chúng tôi xây dựng cơ sở khách hàng lên tới hàng nghìn người, bao gồm những người nổi tiếng, người hoạt động trong xã hội, nhà tạo mẫu, dịch vụ trợ giúp đặc biệt và các trang web thương mại điện tử lớn, tất cả đều chứng thực cam kết của chúng tôi rằng mỗi mặt hàng chúng tôi bán đều là hàng thật. Chúng tôi có một trong những bộ sưu tập trực tuyến lớn nhất về túi xách Hermès mới, mới và như mới trên thế giới, với hàng hóa mới được cung cấp mỗi ngày. Chúng tôi cũng có một bộ sưu tập giới hạn các món đồ cổ điển của Hermès. Ngoài ra, chúng tôi còn có bộ sưu tập túi xách Chanel mới, túi xách Chanel cổ điển, Goyard, đồ trang sức và phụ kiện Hermès và Chanel. Chúng tôi dự trữ 100% các mặt hàng được liệt kê trên trang web của chúng tôi, có sẵn để vận chuyển ngay lập tức. Nếu chúng tôi không có mặt hàng cụ thể mà bạn đang tìm kiếm, đội ngũ trợ giúp đặc biệt của chúng tôi sẵn lòng hỗ trợ tìm nguồn cung ứng mặt hàng bạn mong muốn thông qua chương trình Find It For Me của chúng tôi. Chúng tôi thường giao hàng trong vòng 24 giờ kể từ khi nhận được thanh toán trong ngày làm việc và chúng tôi sẵn sàng trả lời các câu hỏi qua điện thoại, WhatsApp, tin nhắn hoặc email gần như 24/7.",
    app_0006: "1. Bạn có thể liên hệ với chúng tôi qua email:",
    app_0007: "2. Bạn có thể liên hệ với chúng tôi qua điện thoại bán hàng:",
    app_0008: "3. Bạn cũng có thể nhắn tin cho chúng tôi trên WhatsApp:",
    app_0009: "# HOA KỲ",
    app_0010: "  -- Văn phòng của chúng tôi mở cửa từ Thứ Hai - Chủ Nhật, từ 7:00 sáng - 22:00 tối theo giờ chuẩn Thái Bình Dương",
    app_0011: "# Châu Úc",
    app_0012: "  -- Văn phòng của chúng tôi mở cửa từ Thứ Hai - Chủ Nhật, từ 7:00 sáng - 5:00 chiều AEST",
    app_0013: "# Trong những giờ khác, vui lòng để lại tin nhắn thoại hoặc gửi email và chúng tôi sẽ phản hồi nhanh nhất có thể."
};

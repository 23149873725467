export default {
    app_0001: "立即加入",
    app_0002: "我們的任務",
    app_0003: "Gemma 的使命是幫助您找到符合您個人風格的奢華包款和配件。我們找到最令人垂涎​​和最難獲得的配件，所以您不必這樣做。追逐售完的包款、無盡的等待名單以及在您最喜歡的奢侈品牌「玩遊戲」的日子已經一去不復返了。從稀有的愛馬仕包款到鞋子和高級珠寶，我們精選了最全面的全新且從未佩戴過的配件庫存，庫存充足，第二天即可到達您的手中。我們專注於您喜愛的 Hermès Birkin、Kelly、Constance 和 Picotin 手袋以及各種 Hermès 配件、圍巾和珠寶。當您尋找下一個設計師手袋、禮品或投資品時，我們的目標是成為您值得信賴的顧問，致力於尋找與您值得擁有的風格和精緻相匹配的正宗奢華設計師手袋和配件。充滿信心地前進。大膽一點。富有表現力。做你自己。",
    app_0004: "關於我們",
    app_0005: "Gemma於2010年在紐約成立，是美國二級市場上最值得信賴的正品Hermès和Chanel商品經銷商。我們對正品商品和盡可能最佳購物體驗的承諾使我們建立了數千名客戶群，其中包括名人、社交名流、造型師、禮賓服務和大型電子商務網站，他們都證明了我們的承諾我們銷售的每件商品都是正品。我們擁有世界上最大的網上商店新鮮、全新和類新愛馬仕手袋系列之一，每天都會提供新商品。我們也提供限量收藏的愛馬仕復古單品。此外，我們還出售一系列新款香奈兒手袋、香奈兒復古手袋、Goyard、愛馬仕和香奈兒珠寶及配件。我們網站上列出的商品 100% 有庫存，可立即出貨。如果我們沒有您正在尋找的特定商品，我們的禮賓團隊很樂意透過我們的「為我尋找」計畫協助您購買所需的商品。我們通常在工作日收到付款後 24 小時內發貨，並且我們幾乎 24/7 都可以透過電話、WhatsApp、簡訊或電子郵件回覆查詢。",
    app_0006: "1.您可以透過電子郵件聯絡我們：",
    app_0007: "2.您可以透過銷售電話聯絡我們：",
    app_0008: "3. 您也可以透過WhatsApp留言給我們：",
    app_0009: "＃ 美國",
    app_0010: "  -- 我們的辦公室週一至週日上午 7:00 至晚上 22:00（太平洋標準時間）開放",
    app_0011: "＃ 澳大利亞",
    app_0012: "  -- 我們的辦公室週一至週日上午 7:00 至下午 5:00（澳洲東部標準時間）開放",
    app_0013: "＃ 其他時間請留言或發送郵件，我們會盡快回覆。"
};
